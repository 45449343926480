.project-page {
  display: grid;
  grid-template-areas:
    " project-title "
    " project-content "
    " project-carousel "
    " project-code ";
  grid-template-rows: 125px 300px auto auto;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}

.project-title {
  grid-area: project-title;
  margin: 10px 0 0 0;
  padding: 20px;
  background: var(--iah-grey-med);
}
.project-content {
  grid-area: project-content;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
}

.project-carousel {
  grid-area: project-carousel;
  display: grid;
  grid-template-rows: minmax(0, auto);
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0px;
  padding: 0px;
  grid-gap: 0px;
}

.project-code {
  grid-area: project-code;
  margin: 40px;
  border-radius: 10px;
  padding: 40px;
  font-family: "Roboto Mono";
  font-weight: 200;
  font-size: 1.5em;

  border: 1px solid var(--iah-grey-dark);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  background: var(--iah-grey-med);
}

@media (min-width: 600px) {
  .project-page {
    grid-template-areas:
      " . project-title    project-title    . "
      " . project-content  project-content  . "
      " . project-carousel project-carousel . "
      " . project-code     project-code     . ";
    grid-template-rows: 200px 600px auto auto;
    grid-template-columns:
      minmax(20px, 1fr)
      auto
      auto
      minmax(20px, 1fr);
  }

  .project-carousel {
    grid-template-rows: minmax(0, auto);
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0px;
    padding: 0px;
    grid-gap: 20px;
  }
}

@media (min-width: 1200px) {
  .project-page {
    grid-template-areas:
      " . project-title    project-title    . "
      " . project-content  project-content  . "
      " . project-carousel project-carousel . "
      " . project-code     project-code     . ";
    grid-template-rows: 200px 1200px auto auto;
    grid-template-columns:
      minmax(20px, 1fr)
      auto
      auto
      minmax(20px, 1fr);
  }
}
